.formUpdateCharacter {
  margin: 1rem 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
}
.formUpdateCharacter label{
  align-items: center;
}
.formUpdateCharacter .danger{
  width: max-content;
  margin-top: 2rem;
}
.labelFormUpdate {
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
input[type="submit"] {
  align-self: flex-end;
  /* border-radius: 0 !important; */
}
.editContainer label {
  margin: 0.25rem 0;
}
.editBlock {
  /* background-color: #fff; */
  padding: 0.5rem 1rem;
  margin: 10px 0;
}
.editBlock .switch {
  margin-bottom: 0.5rem;
}
.columnForm {
  flex-direction: column;
}
.row {
  display: flex;
  flex-direction: row;
}
label{
  /* display: flex; */
  flex-direction: row;
  flex-wrap: wrap;
}
input[type='number'] {
  flex: 1
}
input[type=text].editNameSkill {
  margin: 5px 0;
  /* padding: 1px 0px; */
  flex: 3
}
input[type=text].editValueSkill {
  text-align:right;
  flex: 1;
}
span.labelSkillUneditable {
  align-self: center;
  flex: 3
}
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.inputfile + label {
  display: inline-block;
  border: 1px solid #ffad23;
  padding: 0.5rem 1rem;
  font-size: 1em;
  color: #ffad23;
  font-size: 0.875rem;
  box-sizing: border-box;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 0.25rem;
  letter-spacing: 0.02857em;
  font-weight: bold;
  cursor: pointer;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.inputfile + label:hover {
  background-color: #ffad23;
  color: white;
}
.inputfile + label * {
	pointer-events: none;
}
.containerEditSkill {
  display: flex;
  flex-direction: column;
}
.containerEditSkill label {
  margin: 0.25rem 0;
  display: flex;
  justify-content: space-around;
}
.frameDemo {
  height: 65px;
  width: 65px;
  cursor: pointer;
  position: relative;
  border: 0.15rem solid transparent;
  margin: 0.5rem;
  border-radius: 0.25rem;
}
.selectedFrame {
  border: 0.15rem solid #1b1d29;
}
.editBlock h3 {
  text-decoration: underline;
}
.optionBtn {
  height: 37px;
  width: 40px;
  padding: 0;
  margin-left: 0.5rem;
  border-radius: 0.25rem;
}
.editContainer .formAssign {
  display: flex;
  flex-direction: column;
}
.editContainer input[type='submit'],.editContainer button {
  width: 100%;
  margin-top: 0.5rem;
}
.editContainer button {
  margin-top: 2rem;
}

@media (min-width: 1000px) {
  .containerEditSkill {
    width: 50%;
  }
  input[type=text].editNameSkill {
    flex: 1
  }
  input[type=text].editValueSkill {
    flex: 1
  }
  span.labelSkillUneditable {
    flex: 1
  }
  input[type="submit"] {
    align-self: flex-end;
  }
  input[type='number'] {
    flex: initial
  }
  .formUpdateCharacter {
    margin: 0;
    display: flex;
  }
  textarea.textAreaDescription {
    width: calc(100% - 5px);
  }
  .editContainer {
    width: 70%;
    margin: auto;
  }
  .editContainer .formAssign {
    display: flex;
    flex-direction: column;
  }
  .formAssign input[type='text'] {
    width: 400px;
  }
  .editContainer button {
    width: auto;
    margin-top: 0.5rem;
  }
  .editContainer input[type='submit'] {
    width: auto;
    margin-top: 0rem;
  }
  .editContainer button {
    margin-top: 2rem;
  }
  .assignInfo{
    font-size: 0.75em;
    font-weight: bold;
  }
}