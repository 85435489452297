.containerCardIcon {
  justify-self: flex-end;
}
.containerCardIcon.tooltip {
  display: flex;
  justify-content: flex-end;
  border-bottom: none;
  height: 3rem;
  position: relative;
}
.containerCardIcon img{
  cursor: pointer;
}
.containerCardIcon.tooltip .tooltiptext{
  min-width: 195px;
  background-color: #1b1d29;
  color: #fff;
  border-radius: 0.25rem;
  padding: 0.5rem;
  left: -210px;
  bottom: 8px;
  cursor: default;
}
.containerCardIcon.tooltip .tooltiptext::before{
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7.5px 0 7.5px 6px;
  border-color: transparent transparent transparent #1b1d29;
  top: 35%;
  right: -6px;
}
.containerCardResume {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  justify-content: center;
}
.playinCard, .playinCardEditable {
  display: flex;
  width: 4rem;
  height: 6rem;
  margin: 0.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border:0.15rem solid #1b1d29;
  background-color: #fff;
  position: relative;
}
.playinCardEditable:hover::before {
  content: '';
  position: absolute;
  bottom: 10px;
  left: 35%;
  border: solid rgba(0,0,0,0.15);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 0.5rem;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  animation: bounceDown 1.25s ease-in-out infinite;
}
.playinCardEditable.used:hover::before {
  top: 10px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  bottom: auto;
  animation: bounceUp 1.25s ease-in-out infinite;
}

.playinCard span,.playinCardEditable span {
  font-size: 1.6em;
  /* cursor: default; */
}
.playinCard span.red, .playinCardEditable span.red {
  color: #D52941;
}
.playinCard span.black, .playinCardEditable span.black {
  color: #1b1d29;
}

@keyframes bounceDown {
  from {bottom: 10px;}
  50%  {bottom: 15px;}
  to   {bottom: 10px;}
}
@keyframes bounceUp {
  from {top: 10px;}
  50%  {top: 15px;}
  to   {top: 10px;}
}

@media (min-width: 1100px) {
  .containerCardResume {
    justify-content: flex-start;
  }
  .playinCard, .playinCardEditable {
    margin: 0.5rem;
  }
}