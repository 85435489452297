.receiptContainer, .potionContainer {
  display: flex;
  flex-direction: column;
}
.containerReceipt {
  display: flex;
  align-items: center;
  min-height: 37px;
}
.potionContainer {
  margin-top: 2rem;
}
.lineReceiptAlchemy {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 5;
  min-height: 26px;
}
.linePotionAlchemy {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 1;
}
.lineReceiptAlchemy.alt {
  background-color: rgba(172, 171, 171, 0.2);
  min-height: 26px;
}
.linePotionAlchemy.alt {
  background-color: rgba(172, 171, 171, 0.2);
}

.lineReceiptAlchemy span{
  align-self: center;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.linePotionAlchemy span{
  align-self: center;
  padding: 0.5rem;
}
.lineReceiptAlchemy span:nth-child(2){
  display: none;
}
.lineReceiptAlchemy span:nth-child(3){
  text-align: end;
}
.linePotionAlchemy span:first-child{
  flex: 6;
}
.linePotionAlchemy span:nth-child(3){
  flex: 1;
  text-align: end;
}

.containerReceipt .blockDelete{
  text-align: end;
  width: 38px;
  z-index: 999;
}
.lineReceiptAlchemy button {
  margin: 0.25rem;
  max-height: 50px;
  max-width: 50px;
}
.receiptEdition button{
  height: auto;
  width: auto;
  line-height: normal;
}
.iconDelete {
  height: 22px;
}
.blockDelete .optionBtnInv {
  margin-left: 0;
}
.receiptEdition {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  flex: 1;
  /* flex-wrap: wrap; */
}
.receiptEdition input, .receiptEdition button {
  max-width: none;
}
.receiptEdition input:nth-child(2){
  display: none;
}
@media (min-width: 1100px) {
  .lineReceiptAlchemy span:nth-child(2){
    flex: 12;
    padding: 0.5rem;
    align-self: center;
    display: flex;
  }
  .lineReceiptAlchemy span:first-child{
    flex: 6;
  }
  .lineReceiptAlchemy span:nth-child(3){
    flex: 1;
    text-align: end;
  }
  .containerReceipt {
    min-height: none;
  }
  .lineReceiptAlchemy input:nth-child(2){
    flex: 12;
    padding: 0.5rem;
    align-self: center;
  }
  .lineReceiptAlchemy input:first-child{
    flex: 6;
  }
  .lineReceiptAlchemy input:nth-child(3){
    flex: 1;
    text-align: end;
  }
  .receiptEdition {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;
  }
  .receiptEdition input{
    width: auto;
  }
  .receiptEdition button {
    width: auto;
    max-width: none;
  }
  .receiptEdition input:nth-child(2){
    display: block;
  }
}