header {
  background-color: #1b1d29;
  /* height: 58px; */
  color: #fff;
  display: grid;
  align-items: space-between;
}
.header {
  width: 100%;
  margin: auto;
  display: grid;
  align-items: space-between;
  grid-template-areas: "logo name log";
}
.header button {
  box-shadow: none;
}
.name {
  display: flex;
  align-self: center;
  justify-self: center;
}
.log {
  display: flex;
  justify-self: flex-end;
}
.log button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.log button span {
  margin: 0 0.5rem;
}
.logo {
  display: flex;
  align-self: center;
}
.logo {
  grid-area: logo
}
.userName {
  grid-area: name;
}
.log {
  grid-area: log;
}
.homeLink {
  padding: 0 0.5rem;
}
.homeLinkIcon {
  width: 3rem;
}
.dropbtn {
  color: white;
  padding: 1rem;
  font-size: 1em;
  border: none;
  border-left: 1px solid #3a3e53;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content .btnDrop {
  color: black;
  padding: 0.5rem;
  text-decoration: none;
  display: block;
  background: none;
  width: 100%;
  text-align: start;
}

.dropdown-content .btnDrop:hover {
  background-color: #ddd;
  border-radius: 0;
}

.dropdown:hover .dropdown-content {
  display: block;
  right: 0;
}
.header button.main {
  background-color: none;
  color: #fff
}

.header .headLinkText {
  height: 100%;
  color: #fff;
  display: flex;
  padding: 0 1rem;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.header .headLinkText:hover, .header .headLink:hover,.header button:hover {
  background-color: rgba(0,0,0,0.2);
}
.header button.empty{
  color: #fff;
}
.header .headLinkText span {
  display: none;
}
.header .headLink {
  height: 100%;
  width: 48px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .headLink .iconH {
  height: 25px;
  color: #fff;
}

/* .dropdown:hover .dropbtn {background-color: #3e8e41;} */
@media (min-width: 800px) {
  .header {
    width: 85%;
  }
}
@media (min-width: 1000px) {
  .header .headLinkText .icon {
    display: none;
  }
  .header .headLinkText span {
    display: block;
  }
}
/* @media (min-width: 1900px) {
  .header {
    width: 70%;
  }
} */
@media (min-width: 2000px) {
  .header {
    width: 70%;
  }
}
@media (min-width: 1500px) {
  .dropdown:hover .dropdown-content {
    right:auto;
  }
}