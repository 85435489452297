.blockInfoDM {
  padding: 0 0.5rem;
}
.contentDm {
  padding-bottom: 50px;
}
.containerInfoCharacterDm {
  display: flex;
  width: 100%;
}
.companyList {
  flex: 1;
}
.infoCharacter {
  flex: 2;
  display: none;
}
.containerCompanyList .blockInfoDM{
  display: flex;
  flex-direction: column;
}
.containerCompanyList .blockInfoDM .stats{
  display: flex;
  flex-direction: column-reverse;
}
.blockInfoDM ul {
  flex: 1;
  border-radius: 0.25rem;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 0.75em;
  margin: 0 10px;
}
.blockInfoDM ul:last-child {
  flex: 2;
}
.blockInfoDM ul li {
  border-bottom: 1px solid #1b1d29;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
}
.blockInfoDM ul li:last-child {
  border-bottom: 0px solid #1b1d29;
}
.blockInfoDM ul li span {
  font-size: 1.25em;
  color: #1b1d29
}
.blockInfoDM h3 {
  text-align: center;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}
.blockInfoDM .subtitle {
  display: none;
}
.memberCompanyList {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem;
  position: relative;
}
.containerCompanyList {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
}
.containerCompanyList img {
  height: 20px;
}
.refreshCompany {
  cursor: pointer;
}
.containerCompanyList button {
  flex: 1;
  align-self: flex-start;
  padding: 0.25rem;
  max-height: 30px;
}
.memberCompanyList .nameHpCharacter{
  flex: 1;
}
.memberCompanyList .sheetIcon {
  position: absolute;
  display: none;
  bottom: 20px;
  left: 135px;
}
.memberCompanyList img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.iconReload{
  height: 30px;
  color: #1b1d29;
  cursor: pointer;
}
.contentDm .danger {
  margin-top: 2rem;
}
.tabsDetailsMobile {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  background-color: #1b1d29;
  height: 60px;
  z-index: 100;
  border-top: 0.5rem solid #fff;
}
.tabsDetailsMobile:before {
  content: "";
  position: absolute;
  background-color: #fff;
  top: -8px;
  height: 8px;
  left: 0;
  right: 0;
}
.mobileTabsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative
}
.mobileTabsContainer .tab {
  display: block;
  padding: 0.5rem;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-top: -15px;
}
.mobileTabsContainer .tab.active {
  background-color: #ffad23;
  border: 0.25rem solid #fff;
  margin-top: -40px;
  /* transition: 0.25s; */
  position: relative;
}
.mobileTabsContainer .tab.active:after, .mobileTabsContainer .tab.active:before {
  content: '';
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #1b1d29;
  top: 20px;
}
.mobileTabsContainer .tab.active:before {
  border-top-right-radius: 50%;
  left: -19px;
  box-shadow: 1px -8px 0 0 #fff;
}
.mobileTabsContainer .tab.active:after {
  border-top-left-radius: 50%;
  right: -19px;
  box-shadow: -1px -8px 0 0 #fff;
}
.DMContainer {
  grid-area: DMContainer;
  grid-gap: 10px;
  display: grid;
}
.containerDMView {
  display: flex;
  justify-content: center;
  height: 100%;
}

.containerDMView .tab {
  /* width: 43%; */
  display: block;
  position: relative;
  text-align: center;
  padding:0.5rem 1rem;
  cursor:pointer;
  border-radius: 0 0 0.25rem 0.25rem;
  color:#1b1d29;
}
.containerDMView .tab.active {
  background-color: #1b1d29;
  /* box-shadow:inset 0px -10em 0px 0px #1b1d29; */
  position:relative;
  color:#fff;
}
.containerDMView .active:after,
.containerDMView .active:before{
  content:'';
  position:absolute;
  top: 0;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
}
.containerDMView .active:after{
  right: -10px;
  box-shadow: -5px -5px 0 #1b1d29;
}
.containerDMView .active:before{
  left: -10px;
  box-shadow: 5px -5px 0 #1b1d29;
}
.containerDMView {
  padding-bottom: 0px;
}
.imagePanelAdminContainer {
  display: flex;
}
.addImagePanel {
  flex: 1;
}
.panelContent {
  flex: 3;
  border-radius: 0.75rem;
  background-color: #fff;
  border: 0.25rem solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  position: relative;
}
.panelContent img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.infoUserDisplay {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.infoUserDisplay span {
  font-size: 0.7rem;
  border-radius: 0.5rem;
  background-color: #1b1d29;
  color: #fff;
  padding: 0.25rem;
  margin: 0.25rem;
  opacity: 0.5;
  cursor: default;
}
.infoUserDisplay span:hover {
  opacity: 1;
}
.containerImagePanel {
  flex: 1;
}
.selectedUser {
  background-color: #007991;
  color: #fff;
  /* border-radius: 0.25rem; */
}
.configPanel {
  display: flex;
  flex-direction: column;
  margin: 1rem 0 1rem 0;
}
.configPanel span{
  padding: 0.5rem;
  cursor: pointer;
  /* margin: 0.1rem; */
}
.configPanel span:first-child, .configPanel span:nth-child(2) {
  font-size: 0.8rem;
}
.configPanel span:nth-child(2) {
  border-bottom: 0.15rem solid #eaeaea;
}
.addImagePanel form {
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem 0 0;
}
.addImagePanel form .guide{
  color:#6c757d;
  font-size: 0.7em;
  text-align: justify;
  margin-bottom: 0.5rem;
}
.addImagePanel form .inputfile + label{
  width: 100%;
  text-align: center;
}
.addImagePanel form input[type='submit'] {
  align-self: flex-end;
  margin: 0 0.5rem 0 0;
}
@media (min-width: 1000px) {
  .panelContent {
    flex: 3;
  }
  .DMContainer {
    grid-template-columns: 3fr 2fr;
    grid-template-rows:3rem 0 0;
    width: 85%;
    justify-content: center;
    align-content: center;
    grid-template-areas:
      "tabsDetails containerHisto"
      "containerInfo containerHisto"
  }
  .DMContainerFull {
    grid-template-rows:3rem 0 0;
    width: 85%;
    justify-content: center;
    align-content: center;
    grid-template-areas:
      "tabsDetails containerHisto"
      "containerInfo containerHisto"
  }
  .containerDMView {
    display: flex;
    justify-content: center;
  }
  .memberCompanyList .sheetIcon {
    display: block;
  }
  .infoCharacter {
    display: block;
  }
  .contentDm {
    margin-top: 3rem;
    padding-bottom: 0px;
  }
  .mobileTabsContainer {
    display: none;
  }
}
@media (min-width: 1400px) {
  .containerCompanyList .blockInfoDM .stats{
    flex-direction: row;
  }
  .panelContent {
    flex: 4;
  }
}