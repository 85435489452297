.containerCharacterView {
  height: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  padding-bottom: 0px;
}
.nameCharacteristics {
  grid-area: nameCharacteristics;
}
.skillsDetail {
  grid-area: skillsDetail;
}
.containerSkill {
  grid-area: containerSkill;
}
.containerHisto {
  grid-area: containerHisto;
}
.tabsDetails{
  grid-area: tabsDetails;
}
.containerInventory{
  /* grid-area: containerInventory; */
}
.containerInv {
  grid-area: containerInv;
}
.containerImagePanel {
  grid-area: containerImagePanel;
}
.alchemyContainer {
  /* grid-area: containerAlchemy; */
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-self: center; */
}

.containerCompany {
  /* grid-area: containerCompany; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-self: center;
}

.diceHistorical {
  grid-area: diceHistorical;
  overflow: auto;
  background-color: #ffffff;
  /* -ms-overflow-style: none;
  scrollbar-width: none; */
}
.diceHistorical::-webkit-scrollbar {
  /* display: none; */
}
/* width */
.diceHistorical::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.diceHistorical::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.diceHistorical::-webkit-scrollbar-thumb {
  background: #e2e2e2;
}

.characterContainer {
  grid-area: characterContainer;
  grid-gap: 10px;
}
.headDetails {
  grid-area: headDetails;
}
.healthDetails {
  grid-area: healthDetails;
}
.healthDetails span:first-child{
  font-weight: bold;
  color:#6c757d;
}
.healthDetails span:nth-child(2){
  font-weight: bold;
  font-size: 1.25em;
}
.descriptionDetails {
  grid-area: descriptionDetails;
}
.descriptionDetails p{
  display: flex;
  align-items: flex-end;
  padding: 0.25rem;
}
.characteristicsDetail {
  grid-area: characteristicsDetail;
}
.characteristicsDetail ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.characteristicsDetail ul li {
  background-color: #1b1d29;
  margin: 10px 5px;
  color: white;
  border-radius: 0.25rem 0.25rem 50px 50px;
  width: 90px;
  height: 105px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.characteristicsDetail ul li span.title {
  font-size: 0.75em;
}
.characteristicsDetail ul li span.subtitle {
  font-size: 0.75em;
  margin-top: -1rem;
}
.characteristicsDetail ul li span.value {
  font-size: 40px;
  margin-top: -1rem;
}
/* .DMContainer {
  display: flex;
  align-items: center;
}
.DMContainer h1{
  margin: 0.5rem 0;
} */
.skillsDetail ul {
  border-radius: 0.25rem;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 0.75em;
  margin: 0 0 20px 0;
}
.skillsDetail ul li {
  border-bottom: 1px solid #1b1d29;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
}
.skillsDetail ul li:last-child {
  border-bottom: 0px solid #1b1d29;
}
.skillsDetail ul li span {
  font-size: 1.25em;
  color: #1b1d29
}
.inventory {
  grid-area: inventory;
  display: none;
}
.characterContainer {
  display: grid;
  /* grid-template-columns: 1fr;
  grid-template-areas:
    "characterDetails"
    "characteristicsDetail"
    "skillsDetail"
    "inventory"
    "containerHisto"; */
}
.linkChatContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.linkChatContainerDmView {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 0.5rem;
}
.headDetails {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
}
.openChat {
  background-color: transparent;
  padding: 0;
  color: #1b1d29;
  box-shadow: none;
}
.iconChat {
  height: 45px;
}
.iconEdit{
  height: 30px;
  color: #ffad23;
}
.iconInvLarge {
  height: 30px;
  margin: 0px 0.5rem;
}
.iconDescriptionOpen{
  height: 15px;
  color: #969595;
}
.namePictureCharacter {
  display: flex;
  align-items: center;
}
.namePictureCharacter h2{
  margin: 0.5rem;
  margin-left: 0;
  max-width: 180px;
}
.hpCharacter {
  display: flex;
  justify-content: space-around;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  position: relative;
  height: 45px;
  align-items: center;
}
.hpCharacter span{
  font-size: 1.25em;
  font-weight: bold;
  word-spacing: 0.5rem;
  margin-bottom: 0.25rem;
  padding: 0.5rem;
  flex: 1;
  text-align: center;
}
.hpCharacter span[contenteditable] {
  outline: 0rem solid transparent;
}
.hpCharacter .hpBar{
  position: absolute;
  left: 0;
  height: 0.25rem;
  background-color: #ffad23;
  bottom: 0;
  z-index: 51;
  max-width: 100%;
}
.hpCharacter .hpBarEmpty{
  position: absolute;
  left: 0;
  height: 0.25rem;
  background-color: #eaeaea;
  bottom: 0;
  width: 100%;
  z-index: 50;
}
table tr td {
  width: 250px;
}
/* table tr td:last-child {
  text-align: right;
} */
.infoDmView {
  color:#007991;
  font-size: 1.5em;
}
.mobileInv {
  display: flex;
  justify-content: center;
  align-content: center;
}
.fullButton span {
  margin: 0 0.5rem;
}
.list a.link {
  text-decoration: none;
}
.characterPicture {
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  margin: 1rem;
  background-repeat: no-repeat;
  position: relative;
}
.characterPicture .framePicture {
  position: absolute;
  top: -15px;
  bottom: -15px;
  left: -15px;
  right: -15px;
  width: 130px;
  height: 130px;
}
.containerPicture {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  align-items: center; 
}
.containerPictureCharacter {
  display: inline-block;
}
.containerPicture div:first-child {
  margin-bottom: 0.5rem;
}
.namePictureCharacter .characterPicture {
  margin: 1rem;
  margin-left: 0;
}
.containerCharacterView .tabsContainer {
  display: flex;
  flex-direction: row;
  border: none;
  position: relative;
  /* margin: 0 3rem; */
}
.memberCompany {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}
input[type='number'].updateHp {
  margin-bottom: 0.5rem;
}
.currentHpDisplay {
  /* height: 35px;
  width: 61px; */
}

.tooltip.tooltipStats {
  border-bottom: none;
  height: auto;
}
.tooltip.tooltipStats .tooltiptext {
  left: auto;
  bottom: auto;
  top: 0px;
  right: -270px;
}
.line.multiLine {
  display: flex;
  flex-direction: column;
}
.line span:first-child {
  text-align: justify;
}
.line span:last-child, .line ul li{
  color: #6c757d;
}

.altOptionContainer {
  display: flex;
  justify-content: space-between;
}
.altOptionContainer div {
  display: flex;
}
.altOptionContainer .blockCoin{
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  cursor: pointer;
}

.altOptionContainer .blockCoin span{
  max-width: 40px;
  max-height: 20px;
  overflow: hidden;
  padding: 0.25rem 0.5rem;
}
.blockCoin input[type='number'], .blockCoin input[type='number']::-webkit-outer-spin-button,.blockCoin input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  border: 0;
  padding: 0.25rem 0;
  background-color: transparent;
  text-align: center;
}
.altOptionContainer .blockCoin input[type='number']{
  width: 38px;
  font-size: medium;
}
.altOptionContainer .blockCoin input[type='number']:focus{
  background-color: #eaeaea;
}
.altOptionContainer .coinIcon{
  height: 35px;
  width: 35px;
  margin-bottom: 0.25rem;
}
.blockCoin:hover span {
  background-color:#eaeaea;
}
.blockCoin:hover input[type='number'] {
  background-color:#eaeaea;
}
.hpCharacter {
  cursor: pointer;
}
.hpCharacter input[type='number'], .hpCharacter input[type='number']::-webkit-outer-spin-button,.hpCharacter input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  border: 0;
  padding: 0.25rem 0;
  background-color: transparent;
  text-align: center;
}
.hpCharacter input[type='number']{
  width: 50px;
  font-size: medium;
  font-size: 1.25em;
  font-weight: bold;
  word-spacing: 0.5rem;
  cursor: pointer;
  flex: 1;
}
.hpCharacter input[type='number']:focus{
  background-color: #eaeaea;
  cursor: text;
}
.hpCharacter:hover input[type='number'] {
  background-color:#eaeaea;
}
.containerCharacterView .tab {
  display: none;
}
.containerHisto .tab.tabText {
  display: none;
}
@media (min-width: 800px) {
  .inventory {
    display: block;
  }
}
@media (min-width: 1000px) {
  .characterContainer {
    grid-template-columns: 3fr 2fr;
    grid-template-rows:3rem 0 0;
    width: 85%;
    justify-content: center;
    align-content: center;
    grid-template-areas:
      "tabsDetails containerHisto"
      "containerInfo containerHisto"
  }
  .containerInfo {
    display: grid;
    /* grid-template-rows:3rem 0rem 0rem;
    grid-template-columns: 1fr 1fr; */
    grid-template-areas:
      "nameCharacteristics skillsDetail"
      "containerInv containerInv"
      "containerAlchemy containerAlchemy"
      "containerImagePanel containerImagePanel"
      "containerCompany containerCompany";
    width: 100%;
    height: fit-content;
  }
  .containerInventory {
    height: 50px;
  }

  .characteristicsDetail ul {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
  .titleSection{
    display: none;
  }
  .characteristicsDetail {
    padding: 0 1rem;
  }
  .skillsDetail {
    padding: 0 1rem;
    justify-content: center;
    align-content: center;
    display: flex;
  }
  .skillsDetail ul {
    margin: 0;
    width: 400px;
  }
  .containerPicture {
    display: flex;
    flex-direction: row;
  }
  .containerCharacterView .tab, .containerHisto .tab {
    /* width: 43%; */
    display: block;
    position: relative;
    text-align: center;
    padding:0.5rem 1rem;
    cursor:pointer;
    border-radius: 0 0 0.25rem 0.25rem;
    color:#1b1d29;
  }
  .containerCharacterView .tab.active, .containerHisto .tab.active {
    background-color: #1b1d29;
    /* box-shadow:inset 0px -10em 0px 0px #1b1d29; */
    position:relative;
    color:#fff;
  }
  .containerCharacterView .active:after,
  .containerCharacterView .active:before,
  .containerHisto .active:active,
  .containerHisto .active:before{
    content:'';
    position:absolute;
    top: 0;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
  }
  .containerCharacterView .active:after, .containerHisto .active:after{
    right: -10px;
    box-shadow: -5px -5px 0 #1b1d29;
  }
  .containerCharacterView .active:before, .containerHisto .active:before{
    left: -10px;
    box-shadow: 5px -5px 0 #1b1d29;
  }
  .containerHisto .tab.tabText {
    display: inline-block;
  }
  .containerHisto .tab.tabIcon {
    display: none;
  }
}
@media (min-width: 1500px) {
  /* .characterContainer {
    width: 85%;
  } */
  .skillsDetail ul {
    margin: 0;
    width: 500px;
  }
  .containerInfo {
    display: grid;
    /* grid-template-rows:3rem 0rem 0rem; */
    /* grid-template-columns: 1fr 2fr; */
    grid-template-areas:
      "nameCharacteristics skillsDetail"
      "containerInv containerInv"
      "containerAlchemy containerAlchemy"
      "containerImagePanel containerImagePanel"
      "containerCompany containerCompany";
    width: 100%;
    height: fit-content;
  }
  .line.multiLine {
    display: flex;
    flex-direction: row;
  }
}
@media (min-width: 2000px) {
  .characterContainer {
    width: 70%;
  }
  .nameCharacteristics {
    max-width: 400px;
  }
}