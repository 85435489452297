.titleSection {
  display: none;
}
.tableInvHeader {
  display: none;
}
.tableInvHeader div:first-child {
  width: 80%;
}
.tableInvRow {
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid #353535;
  padding: 0.5rem 0px;
  align-items: center;
}
.formNewInv {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  /* position: fixed; */
  /* bottom: 0;
  left: 0; */
  width: 100%;
  background-color: #fff;
}
.formNewInv input {
  width: 33%;
  margin: 0 !important;
}
.formNewInv input:focus {
  z-index: 99;
}
.formNewInv input:first-child {
  width: 39%;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.formNewInv input:nth-child(2) {
  width: 33%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: -2px !important;
}
.tableInvRow input[type=number] {
  margin: 0px;
  width: 100%;
}
.tableInvRow div:first-child {
  width: 53%;
}
.formNewInv input[type='text'] {
  padding-left: 1rem;
}
.tableInvRow div:nth-child(2) {
  width: 13%;
}
.tableInvRow div:last-child {
  width: 34%;
  display: flex;
  justify-content: flex-end;
}
.tableInvRow div:last-child button {
  padding: 2px 5px;
  /* border-radius: 0; */
}
.optionBtnInv {
  height: 37px;
  width: 40px;
  padding: 0;
  margin-left: 0.5rem;
  border-radius: 0.25rem;
}
.containerInv {
  margin-bottom: 1.5rem;
}

@media (min-width: 800px) {
  .tableInvHeader {
    display: flex;
    flex-wrap: nowrap;
    background-color: #1b1d29;
    border-radius: 0.25rem 0.25rem 0 0;
    padding: 0.25rem 0.5rem;
  }
  .tableInvHeader span {
    color: #fff;
  }
  .tableInvRow div:first-child {
    width: 50%;
  }
  .formNewInv input[type='text'] {
    padding-left: 0.5rem;
  }
  .tableInvRow div:nth-child(2) {
    width: 30%;
  }
  .tableInvRow div:last-child {
    width: 20%;
  }
  .optionBtnInv {
    height: 25px;
    width: 30px;
    padding: 0;
    margin-left: 0.5rem;
    border-radius: 0.25rem;
  }
  .formNewInv input {
    width: 32%;
    margin: 0 !important;
  }
  .formNewInv {
    position: initial;
    width: auto;
  }
  .containerInv {
    margin-bottom: 0;
  }
  input[type='number'].updateItemNumber {
    height: 19px;
    padding: 0;
    /* padding-left: 0.5rem; */
  }
}
@media (min-width: 1100px) {

}