:root {
  --main-dark: #191919;
  --second-dark: #2a2a2a;
  --light-dark: #363738;
  --grey-text-color: #c4c4c4;
  --main-yellow: #ffad23;
  --main-blue: #007991;
  --light-red: rgba(213, 41, 64, 0.3);
  --white: #ffffff;
}

[data-theme='dark'] {
  background-image: url('../assets/Images/darkBack.png');
}
[data-theme='dark'] header,[data-theme='dark'] .dropdown-content{
  background-color: var(--second-dark);
}
[data-theme='dark'] .link {
  color: #cccccc
}
[data-theme='dark'] h6,[data-theme='dark'] h5,[data-theme='dark'] h4,[data-theme='dark'] h3,[data-theme='dark'] h2,[data-theme='dark'] h1, [data-theme='dark'] span, [data-theme='dark'] label, [data-theme='dark'] p,[data-theme='dark'] pre{
  color: var(--grey-text-color)
}
[data-theme='dark'] a{
  color: var(--main-yellow)
}
[data-theme='dark'] .campaignInformation div p span:first-child {
  color: #939393
}
[data-theme='dark'] .tutoCreation span, [data-theme='dark'] .tutoCreation p {
  color: #fff
}
[data-theme='dark'] .dropdown button {
  background-color: var(--second-dark);
}
[data-theme='dark'] .switch label .lever {
  background-color: rgba(147,147,147,1);
  color: #fff;
}
[data-theme='dark'] input[type=text],[data-theme='dark'] input[type=number],[data-theme='dark'] textarea {
  border: 2px solid var(--second-dark);
  background-color: rgba(0,0,0,0.2);
  color: var(--grey-text-color);
}
[data-theme='dark'] input:focus,[data-theme='dark'] textarea:focus {
  outline: none;
  border: 2px solid #007991;
}
[data-theme='dark'] input[type='number'],[data-theme='dark'] input[type='number']::-webkit-outer-spin-button,[data-theme='dark'] input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  color: var(--grey-text-color);
}

[data-theme='dark'] .skillsContainer .active:after,[data-theme='dark'] .skillsContainer .active:before {
  /* background-color: var(--main-dark); */
  background-color: var(--second-dark);
}

[data-theme='dark'] input[type=submit].disabled,[data-theme='dark'] button.disabled {
  background-color: #161616;
}
[data-theme='dark'] .dropdown-content .btnDrop,[data-theme='dark'] .containerCharacterView .tab {
  color: var(--grey-text-color)
}
[data-theme='dark'] .dropdown-content .btnDrop:hover {
  background-color: #363636;
}
[data-theme='dark'] .containerCharacterView .tab.active {
  background-color: var(--second-dark);
}
[data-theme='dark'] .containerCharacterView .active:after,[data-theme='dark'] .containerCharacterView .active:before {
  background-color: var(--main-dark);
}
[data-theme='dark'] .containerCharacterView .active:before {
  box-shadow: 5px -5px 0 var(--second-dark);
}
[data-theme='dark'] .containerCharacterView .active:after {
  box-shadow: -5px -5px 0 var(--second-dark);
}
[data-theme='dark'] .skillsDetail ul li span, [data-theme='dark'] ul li, [data-theme='dark'] ol li {
  color: var(--grey-text-color);
}
[data-theme='dark'] .skillsDetail ul li {
  border-color: var(--grey-text-color);
}
[data-theme='dark'] .hpCharacter input[type='number'] {
  background-color: var(--main-dark);
  border-color: var(--main-dark);
}
[data-theme='dark'] .hpCharacter:hover input[type='number'] {
  background-color: var(--light-dark);
}
[data-theme='dark'] .hpCharacter input[type='number']:focus {
  background-color: var(--light-dark);
}
[data-theme='dark'] .blockCoin input[type='number'], [data-theme='dark'] .blockSkill input[type='number'] {
  background-color: var(--main-dark);
  border-color: var(--main-dark);
}
[data-theme='dark'] .blockCoin:hover input[type='number'], [data-theme='dark'] .blockSkill:hover input[type='number'] {
  background-color: var(--light-dark);
}
[data-theme='dark'] .blockCoin input[type='number']:focus, [data-theme='dark'] .blockSkill input[type='number']:focus {
  background-color: var(--light-dark);
}
[data-theme='dark'] .hpCharacter .hpBarEmpty {
    background-color: var(--light-dark);
}
[data-theme='dark'] .characteristicsDetail ul li {
  background-color: var(--light-dark);
}
[data-theme='dark'] .myhistoRow {
  background-color: var(--light-dark);
}
[data-theme='dark'] .histoRow {
  background-color: #817f7e;
}
[data-theme='dark'] .myhistoRow .histoLeftSide span:first-child, [data-theme='dark'] .myhistoRow .histoRightSide span {
  color: #fff;
}
[data-theme='dark'] .histoRow .histoLeftSide span:first-child, [data-theme='dark'] .histoRow .histoRightSide span {
  color: #fff;
}
[data-theme='dark'] .tooltip .tooltiptext {
  background-color: #5a5a5a;
}
[data-theme='dark'] .iconBreadcrumb {
  color: var(--grey-text-color);
}
[data-theme='dark'] input[type=submit].outline, [data-theme='dark'] button.outline {
  background-color: var(--main-dark);
}
[data-theme='dark'] input[type=submit].outline:hover, [data-theme='dark'] button.outline:hover {
  background-color: #ffad23;
  color: #fff;
}
[data-theme='dark'] .memberCompany b {
  color: var(--grey-text-color);
}
[data-theme='dark'] .formNewInv {
  background-color: var(--main-dark);
}
[data-theme='dark'] .tableInvHeader {
  background-color: #007991;
}
[data-theme='dark'] button.empty {
  color: var(--grey-text-color);
}
[data-theme='dark'] .playinCard, [data-theme='dark'] .playinCardEditable {
  background-color: var(--second-dark);
  border-color: #404040;
}
[data-theme='dark'] .playinCard span.black,[data-theme='dark'] .playinCardEditable span.black {
  color: var(--grey-text-color);
}
[data-theme='dark'] .playinCardEditable:hover::before {
  border-color: rgba(255,255,255,0.5);
}
[data-theme='dark'] .blockInfoDM ul li {
  border-bottom: 1px solid var(--grey-text-color);
}
[data-theme='dark'] .blockInfoDM ul li span {
  color: var(--grey-text-color);
}
[data-theme='dark'] .nameHpCharacter b {
  color: var(--grey-text-color);
}
[data-theme='dark'] .fullButton {
  background-color: #007991;
}
[data-theme='dark'] .fullButton span {
  color: #fff;
}
[data-theme='dark'] .switch label .lever:before {
  background-color: #404040;
}
[data-theme='dark'] .histoRow .histoLeftSide span {
  color: var(--grey-text-color);
}
[data-theme='dark'] .iconReload {
  color: var(--grey-text-color);
}
[data-theme='dark'] .tabsDetailsMobile {
  background-color: var(--second-dark);
}
[data-theme='dark'] .tabsDetailsMobile:before {
  background-color: var(--main-dark);
}
[data-theme='dark'] .mobileTabsContainer .tab.active:after, [data-theme='dark'] .mobileTabsContainer .tab.active:before {
  background-color: var(--second-dark);
  box-shadow: var(--main-dark);
}
[data-theme='dark'] .mobileTabsContainer .tab.active:after {
  box-shadow: -1px -8px 0 0 var(--main-dark);
}
[data-theme='dark'] .mobileTabsContainer .tab.active:before {
  box-shadow: 1px -8px 0 0 var(--main-dark);
}
[data-theme='dark'] .mobileTabsContainer .tab.active {
  border-color: var(--main-dark);
}
[data-theme='dark'] .mobileTabsContainer .tab.active {
  background-color: #007991;
}
[data-theme='dark'] .navFilterStats ul li {
  background-color: var(--second-dark);
}
[data-theme='dark'] .navFilterStats ul li.active{
  background-color: #414141;
}
[data-theme='dark'] .navFilterStats ul li.divider {
  border-bottom: 0.1rem solid var(--grey-text-color);
  padding: 0;
}
[data-theme='dark'] .containerStats .blockStat {
  background-color: var(--second-dark);
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
[data-theme='dark'] .containerStats .table {
  background-color: var(--second-dark);
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
[data-theme='dark'] .containerStats .tableRow .blockInfo {
  border-bottom: 1px solid var(--main-dark);
}
[data-theme='dark'] .containerStats .custom-tooltip {
  background-color: var(--second-dark);
  border: 1px solid var(--main-dark);
  padding: 0.25rem 0.5rem;
}
[data-theme='dark'] .updatesContainer pre{
  margin-top: 0;
  color: var(--grey-text-color);
  background-color: var(--second-dark);
  border-color: var(--second-dark);
}
[data-theme='dark'] .updatesContainer .headUpdate{
  background-color: var(--second-dark);
}
[data-theme='dark'] .updateDetailContainer {
  background-color: var(--second-dark);
  box-shadow: 0 1px 3px rgba(255,255,255,0.12), 0 1px 2px rgba(255,255,255,0.24);
}
[data-theme='dark'] .updateDetailContainer blockquote {
  border-left: 5px solid var(--second-dark);
}
[data-theme='dark'] .updateDetailContainer code {
  background-color: var(--second-dark);
}
[data-theme='dark'] .updatesContainer .blockLinkUpdate{
  box-shadow: 0 1px 3px rgb(255 255 255 / 12%), 0 1px 2px rgb(114 114 114 / 24%);
  background-color: var(--second-dark);
}
[data-theme='dark'] .myhistoRow .histoLeftSide .updateBlock .iconUpdate,[data-theme='dark'] .histoRow .histoLeftSide .updateBlock .iconUpdate {
  color: var(--grey-text-color);
}
[data-theme='dark'] .ReactModal__Content {
  background-color: var(--second-dark) !important;
  border: 1px solid var(--main-dark) !important;
}
[data-theme='dark'] .ReactModal__Content svg{
  color: var(--grey-text-color);
}
[data-theme='dark'] .Overlay {
  background-color: rgba(138, 138, 138, 0.5);
}
[data-theme='dark'] .updateDetailContainer a{
  color: var(--main-blue);
}
[data-theme='dark'] .contextMenuWindow{
  background-color: var(--second-dark);
  box-shadow: 0 3px 6px rgba(100,100,100,0.1), 0 3px 6px rgba(100,100,100,0.2);
}
[data-theme='dark'] .divider{
  border-color: var(--main-dark);
}
[data-theme='dark'] .contextMenuWindow button.empty {
  color: #007991;
}
[data-theme='dark'] .contextMenuWindow .modifier:hover input[type='number'],
[data-theme='dark'] .contextMenuWindow .modifier input[type='number']:focus{
  background-color: var(--main-dark);
}
[data-theme='dark'] .contextMenuWindow .modifier input[type='number'].error,
[data-theme='dark'] .contextMenuWindow .modifier:hover input[type='number'].error,
[data-theme='dark'] .contextMenuWindow .modifier input[type='number'].error:focus {
  background-color: var(--light-red);
}
[data-theme='dark'] .panelContent {
  background-color: var(--main-dark);
  border-color: var(--second-dark);
}
[data-theme='dark'] .configPanel span:nth-child(2){
  border-bottom-color: var(--second-dark);
}
[data-theme='dark'] span.selectedUser {
  color: var(--white);
}
[data-theme='dark'] form label label {
  color: var(--main-yellow);
}