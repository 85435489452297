.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(27, 29, 41, 0.4);
}
.ReactModal__Content::-webkit-scrollbar {
  display: none;
}
.containerCharacters {
  display: grid;
  grid-gap: 20px;
  grid-template-areas:
    "listCharacters"
    "newCharacterForm";
}
.campaignInformation {
  display: flex;
  flex-direction: column;
}
.campaignInformation div {
  flex: 1;
}
.listCharacters {
  grid-area: listCharacters;
}
.newCharacterForm {
  grid-area: newCharacterForm;
}
.defaultInformation {
  grid-area: defaultInformation;
}
.skillsContainer {
  grid-area: skillsContainer;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  grid-template-areas:
    "createCharacterButton"
    "skills";
}
.createCharacterButton {
  grid-area: createCharacterButton;
}
.characteristics {
  grid-area: characteristics;
}
.characteristics label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.characteristics label span {
  min-width: 200px;
}
.defaultInformation label.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 15px);
}
.textAreaDescription {
  height: 100px;
  margin: 0.5rem 0;
  width: calc(100% - 15px);
  display: block;
}
input[type=text].nameNewCharacter {
  margin: 0.5rem 0px;
  width: calc(100% - 15px);
  display: block;
}
.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.list li a{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* .list li a div{
  display: inline-block;
} */
.skillRow {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
  min-height: 30px;
  align-items: center;
}
.skillRow input[type=number] {
  align-self: flex-end;
  justify-self: flex-end;
  margin: 0 0.25rem;
}
.skillRow label input {
  /* min-width: 25px; */
}
.skillRow .skillName {
    min-width: 220px;
}
.newCharacterForm {
  display: grid;
  grid-gap: 20px;
  grid-template-areas:
    "listCharacters"
    "newCharacterForm";
}
.formNewCharacter {
  display: grid;
  grid-gap: 20px;
  grid-template-areas:
    "defaultInformation"
    "characteristics"
    "skillsContainer"
    "createCharacterButton";
}
.formNewCharacter .switch{
  margin-bottom: 1rem;
}
.inputCharacteristics, .inputSkills {
  width: 50px;
  margin: 0.25rem;
}

.tutoCreation ul {
  list-style-type: circle;
  padding-inline-start: 20px;
}
.tutoCreation ul li {
  margin-bottom: 0.5rem;
}
.tutoCreation {
  background-color: #007991;
  padding: 0.5rem;
  margin-left: -20px;
  margin-right: -20px;
}
.tutoCreation p {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.tutoCreation span, .tutoCreation p, .tutoCreation ul li{
  color: #fff
}
.skillsContainer .tabsContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  border-bottom:3px solid #ffad23;
  position: relative;
  margin: 0 -20px;
}
.skillsContainer .tab {
  width: 50%;
  position: relative;
  text-align: center;
  padding:0.5rem 1rem;
  cursor:pointer;
  border-radius: 0.25rem 0 0 0;
  color:#ffad23;
}
.skillsContainer .tab:first-child {
  border-radius: 0 0.25rem 0 0;
}
.skillsContainer .tab.active {
  background-color: #ffad23;
  /* box-shadow:inset 0px -10em 0px 0px #ffad23; */
  position:relative;
  color:#fff;
}
.skillsContainer .active:after,
.skillsContainer .active:before{
  content:'';
  position:absolute;
  bottom: 0;
  width:10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
}
.skillsContainer .active:after{
  right: -10px;
  box-shadow: -5px 5px 0 #ffad23;
}
.skillsContainer .active:before{
  left: -10px;
  box-shadow: 5px 5px 0 #ffad23;
}
.campaignInformation div p span:first-child {
  color: #6c757d;
}
.settingsCampaign .switch {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.ReactModalPortal h2 {
  margin-block-start: 0.25rem;
  margin-block-end: 0.25rem;
}
.ReactModalPortal::-webkit-scrollbar {
    display: none;
}
@media (min-width: 500px) {
  .containerCharacters {
    grid-template-areas:
      "listCharacters"
      "newCharacterForm";
  }
  .campaignInformation {
    display: flex;
    flex-direction: row;
  }
  .defaultInformation label.column {
    width: 510px
  }
  .textAreaDescription {
    min-height: 100px;
    max-width: 490px;
    min-width: 490px;
  }
  input[type=text].nameNewCharacter {
    margin: 0.5rem 0px;
    width: auto;
    display: inline-block;
  }
  .skillsContainer {
    justify-content: flex-start;
  }
  .defaultInformation label.column {
    width: 80%
  }
  .textAreaDescription {
    min-height: 100px;
    max-width: 80%;
    min-width: 80%;
  }
  .tutoCreation {
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 0.25rem;
  }
  .tabsContainer {
    margin: 0;
  }
}
@media (min-width: 800px) {
  .containerCharacters {
    grid-gap: 20px;
    grid-template-areas:
      "listCharacters"
      "newCharacterForm";
   }
  .listCharacters .list .link {
    margin-left: 0.25rem;    
    margin-right: 0.25rem;    
  }
   .formNewCharacter {
    display: grid;
    grid-template-columns: 1fr 0fr;
    grid-gap: 20px;
  }
  input[type=text].nameNewCharacter {
    margin: 0.5rem 0px;
    width: auto;
    display: inline-block;
  }
  .defaultInformation label.column {
    width: 510px
  }
  .textAreaDescription {
    min-height: 100px;
    max-width: 490px;
    min-width: 490px;
  }
  .characteristics {
    grid-area: characteristics;
    max-width: auto;
  }
  .list {
    justify-content: flex-start;
  }
  .list li a div{
    display: inline-block;
  }
  .list li a{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .skillRow {
    justify-content: flex-start;
  }
}
@media (min-width: 1300px) {
  .formNewCharacter {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    /* grid-template-areas:
    "defaultInformation"
    "characteristics"
    "characteristics"
    "skillsContainer"; */
  }
  .createCharacterButton {
    display: flex;
    justify-content: flex-end;
  }
}
