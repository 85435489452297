.containerCampaigns {
  display: grid;
  grid-gap: 20px;
  grid-template-areas:
    "campaignList"
    "formNewCampaign"
    "formJoin"
}
.formFullWidthMobile {
  display: flex;
}
.formFullWidthMobile input[type='text'] {
  display: flex;
  flex: 1;
  /* margin: 0px 5px; */
}
.campaignList ul {
  margin: 0;
}
.campaignList ul li{
  margin: 0.5rem 0;
}
@media (min-width: 500px) {
  .containerCampaigns {
    padding-top: 30px;
    grid-template-columns: 5fr 4fr;
    grid-template-areas:
      "campaignList    campaignList"
      "formJoin    formNewCampaign";
  }
  nav ul {
    display: flex;
    justify-content: space-between;
  }
  .campaignList ul {
    display: flex;
    flex-wrap: wrap;
  }
  .campaignList ul li{
    margin: 0.5rem;
    /* margin: 10px 8px; */
  }
  .formFullWidthMobile {
    display: flex;
  }

}
@media (min-width: 800px) {
  .containerCampaigns {
    grid-gap: 20px;
    grid-template-columns: 5fr 4fr;
    grid-template-areas:
      "campaignList    campaignList"
      "formJoin    formNewCampaign";
  }
  nav ul {
    flex-direction: column;
  }
  .formFullWidthMobile input[type='text'] {
    flex: none;
    width: 220px;
  }
}

.campaignList {
  grid-area: campaignList;
}

.formNewCampaign {
  grid-area: formNewCampaign;
}
.formJoin {
  grid-area: formJoin;
}
.mainContainer {
  padding: 0px 20px;
}
