/*
#ffad23
#ea9010
#eaeaea
#6c757d
#007991
#1b1d29
#D52941
#3bc14a
*/
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-image: url('./assets/Images/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-attachment: fixed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}
#root { 
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
.loaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center
}
select {
  background: white;
  border-radius: 0.25rem;
  padding: 0.5rem;
  border: 2px solid #E7E7EE;
}
input[type=text], input[type=number], textarea {
  border: 2px solid #E7E7EE;
  padding: 0.5rem;
  border-radius: 0.25rem;
}
input:focus, textarea:focus {
  outline: none;
  border: 2px solid #007991;
}
.click {
  cursor: pointer;
}
.defaultCursor {
  cursor: default;
}
.editable {
  cursor: context-menu;
}
input[type=submit], button {
  background-color: #ffad23;
  padding: 0.5rem 1rem;
  color: #fff;
  font-size: 1em;
  border: none;
  font-size: 0.875rem;
  /* min-width: 64px; */
  box-sizing: border-box;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 0.25rem;
  letter-spacing: 0.02857em;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid transparent;
  /* text-transform: uppercase; */
}
input[type=submit].outline, button.outline {
  border: 1px solid #ffad23;
  color: #ffad23;
  background-color: transparent;
}
input[type=submit].danger, button.danger {
  border: 1px solid #D52941;
  color: #D52941;
  background-color: transparent
}
input[type=submit].danger:hover, button.danger:hover {
  color: #fff;
  background: #D52941;
}
input[type=submit].dropbtn, button.dropbtn {
  border-radius: 0;
}
input[type=submit].outline:hover, button.outline:hover {
  color: #fff;
  background: #ffad23;
}
input[type=submit].disabled, button.disabled {
  background-color: #D9D9D9;
  cursor: default;
}
input[type=submit].main, button.main {
  background-color: #1b1d29;
}
button.empty {
  background-color: transparent;
  color: #1b1d29;
  box-shadow: none;
}

input[type=submit].black, button.black {
  background-color: #0A0A0A;
}
.link {
  color :#1b1d29;
  text-decoration: none;
}
.linkMain {
  color :#ffad23;
  /* text-decoration: none; */
}
.link:hover {
  color :#1b1d29;
  /* text-decoration: underline; */
}
.fullButton {
  width: 100%;
  padding: 0.5rem;
  background-color: #1b1d29;
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 0.25rem;
}
.fullButton:hover {
  color :#fff;
  text-decoration: underline;
}
ul {
  padding-inline-start: 0;
  list-style-type: none;
}
input[type=checkbox] {
  display: none;
}
.switch {
  height: fit-content;
}
.switch label {
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: max-content;
}
.settingsCampaign .switch label {
  width: fit-content;
  flex-direction: row-reverse;
}
.switch label input[type=checkbox]:checked + .lever {
  background-color: #ffad23;
}
.switch label .lever {
  content: "";
  display: inline-block;
  position: relative;
  width: 36px;
  min-width: 36px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.20);
  border-radius: 1rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  transition: background 0.3s ease;
  vertical-align: middle;
  /* margin: 0 16px; */
}
.switch label .lever:before {
  /* background-color: rgba(38, 166, 154, 0.15); */
}
.switch label input[type=checkbox]:checked + .lever:after {
  background-color: #fff;
}
.switch label .lever:before,
.switch label .lever:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  left: 2px;
  top: 2px;
  transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease, -webkit-transform 0.1s ease;
  transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease, transform 0.1s ease;
  transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease, transform 0.1s ease, -webkit-transform 0.1s ease;
}
.switch label .lever:after {
  background-color: #fff;
  /* box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); */
}
.switch label input[type=checkbox]:checked + .lever:before,
.switch label input[type=checkbox]:checked + .lever:after {
  left: 18px;
}
.switch.disabled label .lever {
  background-color: rgba(0, 0, 0, 0.30);
}
.switch.disabled label .lever:after {
  background-color: rgba(0, 0, 0, 0.20);
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #1b1d29;
  height: 17px;
  cursor: help;
}
.tooltip .tooltiptext {
  visibility: hidden;
  min-width: 250px;
  background-color: #1b1d29;
  color: #fff;
  border-radius: 0.25rem;
  padding: 0.5rem;
  left: 0;
  bottom: 20px;
  position: absolute;
  z-index: 1;
}
.tooltip .tooltiptextMobile {
  visibility: hidden;
  min-width: 65px;
  background-color: #55565d;
  color: #fff;
  border-radius: 0.25rem;
  padding: 0.5rem;
  right: 0;
  bottom: 10px;
  position: absolute;
  z-index: 1;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip:hover .tooltiptextMobile {
  visibility: visible;
}

.tooltipDice {
  height: 58px;
  border-bottom: none;
  cursor: pointer;
}
.tooltipDice .tooltiptext {
  min-width: 65px;
  left: 58px;
  bottom: 10px;
  text-align: center;
}
.tooltipDice .tooltiptextMobile {
  right: 58px;
  bottom: 10px;
  text-align: center;
}

.Toastify__toast--warning {
  background: #ffad23 !important;
}
.Toastify__toast--error {
  background: #D52941 !important;
}
.Toastify__toast--success {
  background: #007991 !important;
}
.toastBody {
  /* font-family: "Atlas Grotesk Web", Arial, Helvetica, sans-serif; */
  color: #fff; /* #10171D */
  font-size: 0.875rem !important;
}
.toastProgress {
  background: transparent !important;
}
.iconBreadcrumb {
  height: 20px;
  color: #1b1d29
}
[contenteditable]:read-write:focus {
  outline: 0.15rem solid #1b1d29;
  border-radius: 0.25rem;
}
.iconTabMobile {
  width:35px;
  height:35px;
  color: #fff
}
@media (min-width: 800px) {
  .compactPage {
    width: 70%;
    margin: auto
  }
}
@media (min-width: 1900px) {
  .compactPage {
    width: 60%;
    margin: auto
  }
}