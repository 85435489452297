.listHisto {
  flex-direction: column-reverse;
  display: flex;
  overflow: auto;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 0.5rem;
}
.diceChatContainer {
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.diceChatContainer::-webkit-scrollbar {
  display: none;
}
.bubbleHisto, .bubbleHistoUpdate{
  display: flex;
  align-items: center;
  border-radius: 0rem 0.75rem 0.75rem 0;
  min-height: 70px;
  width: 210px;
  margin: 0.25rem 1.5rem;
  padding: 0 0.5rem;
  justify-content: space-between;
  font-weight: bold;
  overflow: hidden;
  /* -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; */
}
.bubbleHistoUpdate{
  /* width: 300px; */
  width: fit-content;
  min-width: 200px;
  padding: 0, 0.25rem;
  word-wrap: break-word;
}
.histoRow {
  background-color: #eaeaea;
  margin: 0.15rem 2.25rem;
}
.histoRow span {
  color: #1b1d29;
}
.myhistoRow {
  border-radius: 0.75rem 0 0 0.75rem;
  background-color: #1b1d29;
  align-self: flex-end;
  margin: 0.15rem 0.5rem;
}

.myhistoRow span {
  color : #eaeaea;
}
.bubbleHisto span:first-child {
  max-width: 150px;
  word-break: break-word;
}
.histoLeftSide {
  display: flex;
  flex-direction: column;
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  /* overflow: hidden; */
}
.histoLeftSide span {
  font-size: 0.7em;
  font-style: italic;
  font-weight: normal;
}
.histoRow .histoLeftSide span {
  color:#6c757d
}
.histoRow .histoLeftSide span:first-child {
  color: #1b1d29;
}
.myhistoRow .histoLeftSide span {
  color:#a5a2a2
}
.myhistoRow .histoLeftSide span:first-child {
  color: #eaeaea;
}
.myhistoRow .histoLeftSide .updateBlock span {
  color: #eaeaea;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
}
.histoRow .histoLeftSide .updateBlock span {
  color: #1b1d29;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
}
.myhistoRow .histoLeftSide .updateBlock .iconUpdate {
  margin: 0 0.5rem;
  color: #eaeaea;
}
.histoRow .histoLeftSide .updateBlock .iconUpdate {
  margin: 0 0.5rem;
  color: #1b1d29;
}
.histoLeftSide span:first-child {
  font-size: 1em;
  font-style: normal;
  font-weight: bold;
  /* font: .85rem/1.5 Arial; */
}
.histoView {
  display: flex;
  justify-content: center;
  flex-direction: column;
  overscroll-behavior: contain;
}

#last {
  width: 250px;
  height: 120px;
}
#last .histoRightSide {
  font-size: 1.7em;
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  /* min-width: 100px; */
  position: relative;
}
#last .histoRightSide span{
  margin-left: 0.5rem;
}
.histoRightSide {
  font-size: 1em;
}
.histoRightSide #animationHisto {
  font-size: 1.2em;
  position: absolute;
  right: 55px;
}
.histoRightSide .critAnim.histoSuccesCritIcon {
  color: #ffad23;
  /* color: #3bc14a; */
  /* opacity: 0;
  top: 20px; */
  animation-duration: 1.2s;
  animation-name: newSuccessCrit;
  /* animation-fill-mode: forwards; */
  animation-timing-function: linear;
}
.histoRightSide .critAnim.histoFailCritIcon {
  color: #D52941;
  animation-duration: 0.5s;
  animation-name: newFailCrit;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}
.userPictureRoll {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: flex-end;
  position: absolute;
  bottom: 0.75rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.pictureAnimated {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  align-self: flex-end;
  margin: 0.5rem 0.5rem 0px 0.5rem;
  /* -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out; */
  position: absolute;
  bottom: 105px;
  /* animation: pictureAnimation 0.5s ease-in-out 0.2s 1 normal forwards; */
}
.myhistoRow.lastOfGroup {
  border-radius: 0.75rem 0 0.75rem 0.75rem;
}
.myhistoRow.firstOfGroup {
  border-radius: 0.75rem 0.75rem 0 0.75rem;
}
.histoRow.lastOfGroup {
  border-radius: 0rem 0.75rem 0.75rem 0.75rem;
}
.histoRow.firstOfGroup {
  border-radius: 0.75rem 0.75rem 0.75rem 0;
}

.myhistoRow.aloneOnGroup {
  border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
}
.histoRow.aloneOnGroup {
  border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
}


@keyframes pictureAnimation {
  from {
    bottom: 105px;
    opacity: 1;
  }
  to {
    bottom: 5px;
  }
}

@keyframes newBubbleAnimation {
  from {
    width: 250px;
    height: 120px;
  }
  to {
    width: 210px;
    height: 70px;
  }
}

@keyframes newSuccessCrit {
  0% {
    transform: translate(0, 0%) scale(1, 1);
    top: 0px;
  }
  10% {
    transform: translate(0, 0%) scale(1, 0.75);
    top: 2px;
  }
  30% {
    transform: translate(0, 0%) scale(1, 1);
    top: 0px;
  }
  50% {
    transform: translate(0, -120%) scale(1, 1);
  }
  55% {
    transform: translate(0, -120%) rotate(15deg);
  }
  60% {
    transform: translate(0, -100%) rotate(-15deg);
  }
  65% {
    transform: translate(0, -100%) rotate(15deg);
  }
  70% {
    transform: translate(0, -100%) rotate(-15deg);
  }
  80% {
    transform: translate(0, -100%) rotate(0deg);
  }
  100% {
    transform: translate(0, 0%) scale(1, 1);
  }
}

@keyframes newFailCrit {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.headerHisto {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}
.headerHisto:first-child {
  margin-top: 0;
}
.headerHisto:nth-child(2) {
  justify-content: flex-end;
}
.containerRow {
  display: flex;
  flex-direction: row;
  position: relative;
}
.containerRowReverse {
  display: flex;
  position: relative;
  flex-direction: row-reverse;
}
.listHisto span.date {
  color: #6c757d;
  font-size: 0.7em;
  font-style: italic;
  margin-left: 1rem;
}
.noDiceMessage {
  display: flex;
  align-self: center;
  color: #6c757d;
}
.iconHide {
  height: 20px;
  width: 20px;
}
.myhistoRow .iconHide {
  color : #eaeaea;
}
.headerHisto button {
  padding: 0;
}
@media (min-width: 1000px) {
  .listHisto {
    margin-bottom: 0px;
  }
}