.containerStats {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.navFilterStats {
  color: #fff;
  height: fit-content;
}
.navFilterStats ul {
  margin-block-start: 0em;
  margin-block-end: 0em;
  cursor: pointer;
  /* margin-top: 0.5rem; */
}
.navFilterStats ul li {
  cursor: pointer;
  padding: 0.5rem 1rem;
  background-color: #1b1d29;
}
.navFilterStats ul li:first-child {
  /* border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem; */
}
.navFilterStats ul li:last-child {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.navFilterStats ul li.active{
  background-color: #090916;
}
.navFilterStats ul li.divider {
  border-bottom: 0.1rem solid #4d4e58;
  padding: 0;
}
.statsCampaign {
  flex: 1
}
.globalStats {
  display: flex;
  flex-direction: column-reverse;
}
.statsCampaign .multiLineStat ul{
  margin-block-end: 0;
  margin-block-start: 0;
  text-align: end;
}
.statsCampaign .multiLineStat ul li{
  margin-bottom: 0.25rem;
}
.statsCampaign div.line{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.05rem #d6d6d6 solid;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.statsCampaign .columnChart {
  display: flex;
  flex-direction: row;
  width: fit-content;
}
.statsCampaign .columnChart.longChart {
  display: flex;
  flex-direction: column;
  width: fit-content;
}
.statsCampaign .shortChart {
  display: flex;
  flex-direction: row;
  width: fit-content;
}
.statsCampaign .chart {
  max-width: 20%;
  margin: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: inline-block;
  flex: 1;
}
.statsCampaign .columnChart h3, .statsCampaign .longChart h3, .statsCampaign .shortChart h3{
  font-size: 0.75rem;
  text-align: center;
  color:#666;
}
.statsCampaign .longChart {
  /* margin: 0.5rem; */
  /* margin-top: 1rem; */
  margin-bottom: 1rem;
  width: fit-content;
}
.multiBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.radar {
  display: none;
}
.statsPerDate {
  width: 100%;
  max-width: 100%;
}
.table {
  flex-direction: row;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  margin: 1rem 0.5rem;
  border-radius: 0.5rem;
  background-color: #fff;
  width: 1000px;
}
.tableColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.tableRow {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.tableRow .blockInfo{
  flex: 1;
  display: flex;
  min-width: 100px;
  border-bottom: 1px solid #e7e7e7;
  min-height: 36px;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}
.tableRow .blockInfo.head{
  font-weight: bold;
}
.tableRow .blockInfo:first-child{
  justify-content: flex-start;
}
.tableRow .blockInfo:last-child{
  justify-content: center;
}
.blockStat {
  background-color: #fff;
  margin: 0.5rem;
  width: fit-content;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 0.5rem;
}
.blockStat.fix {
  width: 300px;
  height: 160px;
  display: flex;
  flex-direction: column;
}
.blockStat.fix div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blockStat.fix div span{
  font-size: 4em;
  margin-top: -20px;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
}
.custom-tooltip {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  padding: 0.25rem 0.5rem;
}
.legendCharts {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.legendCharts span{
  padding: 0 1rem;
}
.noRollContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
}
.noRollContainer span {
  color: #8c8d95;
}
@media (min-width: 1500px) {
  .containerStats {
    width: 80%;
    margin: auto;
  }
  .globalStats {
    flex-direction: row;
  }
  .statsCampaign .columnChart {
    display: flex;
    flex-direction: column;
    width: fit-content;
  }
  .statsCampaign .columnChart.longChart {
    display: flex;
    flex-direction: column;
    width: fit-content;
  }
  .table {
    width: 1200px;
  }
}