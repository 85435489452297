.open {
  display: block;
  z-index: 10;
}
.close {
  height: 0px;
  z-index: 10;
  display: none;
}
.containerRollButton {
  position: fixed;
  bottom: 20px;
  left: 20px;
}
.containerRollButtonPhone {
  position: fixed;
  bottom: 10px;
  right: 20px;
}
.subChoice {
  display: flex;
  flex-direction: column;
  z-index: 1;
}
button.subRoll {
  margin: 0.25rem 0 0.25rem 0;
  min-width: none;
  border-radius: 50%;
  padding: 0;
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-content: center;
}
button.subRoll svg, button.subRoll span{
  align-self: center;
}
button.mainRoll {
  width: 52px;
  height: 52px;
  padding: 0;
}